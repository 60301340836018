.qr-code-wrapper {
  display: inline-block;
}
.qr-code-wrapper .has-qr-code-image-box {
    width: 100%;
    height: 100%;
    position: relative;
}
.qr-code-wrapper .has-qr-code-image-box .qr-code-image {
      width: 100%;
      height: 100%;
}
.qr-code-wrapper .has-qr-code-image-box .qr-code-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
}
.qr-code-wrapper .no-qr-code-image-box {
    background-color: #e5e5e5;
    text-align: center;
    margin: 0 auto;
    height: 100%;
    width: 100%;
}
